import React from 'react';
import axios from 'axios';
import  { Redirect, useHistory } from 'react-router-dom'
import { Collapse } from 'antd';
import {Link} from 'react-router-dom';
import SvForm from './SvForm';
import RenderMatch from './renderMatch';
const { Panel } = Collapse;

export default class RenderSV extends React.Component{
    state = {
        me: [],
        sv:[],
        id_partner:[]

    };
     
componentDidMount(){
    let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("COToken"),
        },
      };
    axios.get('/api/me', config).
    then(res=>{
        this.setState({me: res.data.match2});
        this.setState({id_partner: res.data.id_partner});
    console.log(res.data);

    }).catch(res=>{this.setState({credenziali: 'errore'})});

}

render(){
  
        if(this.state.id_partner!=0){
            if(this.state.me==0){
              return (
                <h1>
                  Al momento non si è verificato alcun match: basta aspettare ;)
                  <br/>
                 
                </h1>
              );}

           else{
            return<RenderMatch/>
            
          }
    
}
return <SvForm/>
}
}
//base FUNZIONANTE per ottenere elementi. Ricorda che se vi è un solo risultato devi sostituire map con 
//this.state.persons.name(nel caso specifico)
