import React, { Component } from 'react'
import Name from './Name'
import {Link} from 'react-router-dom';
import { List, Avatar } from 'antd';

class NamesContainer extends Component {
    render() {
        return (
            <div style={{'overflow':'hidden', 'height': '10%', 'padding':'1%'}}>
                 <List
    itemLayout="horizontal"
    dataSource={this.props.names}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          title={<a href={`/${item.tipo}/${item.elem}`}>{item.nome}</a>}
          description={item.tipo}
        />
      </List.Item>)}
  />,
              
            </div>
        )
    }
}
export default NamesContainer