import axios from 'axios';

const tokenName = "COToken";

class User {
  constructor() {
    this.token = false;
    this.user = {};

    const token = localStorage.getItem(tokenName);
    if (token !== null) {
      const config = {headers: {Authorization: "Bearer " + token}};
      axios.get('/api/me', config).then(res => {
        this.token = token;
        this.user = res.data;
        console.log(res.data);
      }).catch((error) => {
        console.error(error);
        this.token = false;
        this.user = {};
      });
    }
  }

  setLogged(token) {
    localStorage.setItem(tokenName, token);
    this.token = token;
  }

  logout() {
    localStorage.removeItem(tokenName);
    this.token = false;
  }

  isLogged() {
    return this.token !== false;
  }

  getUser() {
    return this.user;
  }
}

export default new User();