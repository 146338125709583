import React from 'react';
import './App.css';
import {Layout} from 'antd';
import Registrati from './registrati';
import NormalLoginForm from './formlogin'; //i componenti definiti fuori non richiedono parentesi graffe
import {Menu} from 'antd';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import RenderSV from './renderSV';
import Page404 from './page404';
import user from './helpers/User';

const {Header, Footer, Sider, Content} = Layout;

function PrivateRoute({component, ...rest}) {
  const render = (props) => user.isLogged() ?
    React.createElement(component, props) :
    <Redirect to={{pathname: "/", state: {from: props.location}}}/>;
  return (<Route {...rest} render={props => render(props)}/>);
}

function UnloggedRoute({component, ...rest}) {
  const render = (props) => !user.isLogged() ?
    React.createElement(component, props) :
    <Redirect to={{pathname: "/eventi", state: {from: props.location}}}/>;
  return (<Route {...rest} render={props => render(props)}/>);
}

function App() {
  const menuItems = [{key: 0, link: '/', name: 'Cavour Online'}];
  if (user.isLogged()) {
    menuItems.push({key: 3, link: '/eventi', name: 'San Valentino Segreto'});
  }

  return (
    <Router>
    <div className="App">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["0"]}>
          {menuItems.map((i) => <Menu.Item key={i.key}><Link to={i.link}>{i.name}</Link></Menu.Item>)}
        </Menu>
      </Header>
        <Switch>
          <PrivateRoute path={'/eventi'} component={RenderSV}/>
          <UnloggedRoute path={'/registrati/:id'} component={Registrati}/>
          <Route path={'/'} exact component={NormalLoginForm} />
          <Route component={Page404} />
        </Switch>
      </div>
    </Router>
  );
  //puoi importare il componente da fuori o definirlo qui sotto; Switch sceglie il primo match in una serie di routes
  //in teoria anche about matcherebbe con /, per questo exact
}

export default App;
