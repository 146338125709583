import React, {useState} from 'react';
import {Form, Input, Button, Checkbox} from "antd";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import axios from "axios";
import {Redirect, useHistory} from "react-router-dom";
import {NoticeBar, WhiteSpace, Icon} from "antd-mobile";
import user from "./helpers/User";

const NormalLoginForm = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const onFinish = (values) => {
    setLoading(true);
    const data = {
      email: values.email,
      password: values.password,
    };
    axios.post("/api/login", data).then((response) => {
      if (response.data.token !== false) {
        user.setLogged(response.data.token);
        return history.push("/eventi");
      } else {
        setErrorMessage('Credenziali non valide.');
      }
    }).catch((error) => {
      setErrorMessage('Si è verificato un errore, riprovare più tardi');
      console.log(error);
    }).then(() => {
      setLoading(false);
    });
  };

  return (
    <div id='login-wrapper'>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <h1>Benvenuto su Cavour Online</h1>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Per favore, inserisci una mail",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon"/>}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Inserisci una password',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        {/* <Form.Item
        rules={[
          {

            required: true,
            message: 'È necessario accettare i termini per accedere',
          },
        ]}
        name="remember" valuePropName="checked" noStyle>
         <Checkbox>Accetto i Termini di Servizio</Checkbox>
        </Form.Item>*/}
        {/*
        <a className="login-form-forgot" href="" >
          Ho dimenticato la password
        </a>*/}


        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>Accedi</Button>
          {errorMessage ? <div className="error">{errorMessage}</div> : ""}
          <br/>
          <br/>

          L'accesso a questo sito è riservato agli studenti del Liceo Cavour. Consulta la mail scolastica.
          <br/>
          <b>Accedendo accetti i Termini di Servizio specificati nella mail</b>
        </Form.Item>
        <WhiteSpace size="lg"/>
      </Form>
    </div>
  );
};
export default NormalLoginForm;
