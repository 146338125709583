import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import { NoticeBar, WhiteSpace, Icon } from "antd-mobile";
import { Radio } from "antd";

import React from "react";
import SearchBar from "./searchBar";

const SvForm = () => {
  const [value, setValue] = React.useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("COToken"),
    },
  };
  const history = useHistory();
  const onFinish = (values) => {
    
  axios.post('/api/init',  {'phonenumber': values.tel, 'preferenza': values.preferenza, 'id_partner': values.partner}, config)
    .then(response=>{history.push('/home')
    
    
  
  }).catch(error=>{console.log(error)})
  }
  


 
   

    //response.data.prova[0])})
  

  //response.data.prova[0])})

  return (
    <div id="form-wrapper">
    <h1>San Valentino Segreto</h1>
    <Form style={{'marginTop':'1%'}}
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
     
      
        <Form.Item
        name="partner"
        rules={[
          {
            required: true,
            
            message: 'Inserisci l\'id del tuo partner',
          },
        ]}
      >
        <Input type=''
        prefix={<UserOutlined className="site-form-item-icon" />}
         placeholder="L'id del tuo spasimante" />
    
        
        </Form.Item>
        <h4>
          Per trovare l'id del tuo amato, puoi usare la barra interattiva qui sotto
        </h4>
        <SearchBar/>
        <Form.Item
        name="tel"
        rules={[
          {
            required: false,
            min: 10,
            message: "Inserisci un numero di telefono valido",
          },
        ]}
      >
        <Input type='tel'
        prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Facoltativo, inserisci il tuo numero di cellulare: la tua anima gemella lo ricerverà se doveste matchare" />
      </Form.Item>
      <Form.Item
        name="preferenza">
       <h4>In caso non dovessi matchare, potresti essere accoppiato casualmente con un altro ragazzo della scuola. 
         Indica il sesso dal quale ti senti maggiormente attratto.
       </h4>
      
        
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={"M"}>Uomo</Radio>
          <Radio value={"F"}>Donna</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Procedi
        </Button>
      </Form.Item>
      <WhiteSpace size="lg" />
    </Form>
    </div>
  );
};

export default SvForm;
