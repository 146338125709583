import React from 'react';
import './App.css';

function Page404() {
  return (
    <div>
      <b>PAGINA NON TROVATA</b>
    </div>
  );
}

export default Page404;