import React from 'react';
import axios from 'axios';

export default class RenderMatch extends React.Component{
    state = {
        email: [],
        tel: [],
        name: [],
        
        match2:[]
    };
   
componentDidMount(){
    let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("COToken"),
        },
      };
    axios.get('/api/me', config).
    then(res=>{
    axios.get(`/api/vedi_studente?id=${res.data.match2}`, config).
    then(res2=>{this.setState({email: res2.data.studente.email});
    this.setState({tel: res2.data.studente.phonenumber});
    this.setState({name: res2.data.studente.name + ' '+ res2.data.studente.surname});

});

    
    console.log(res.data);

    });
}

render(){
    return(
      <>
      <h1>Congratulazioni, ha matchato con {this.state.name}</h1>
      <h2>Il suo numero di telefono è {this.state.tel}</h2>
      <h2>Il suo indirizzo mail è {this.state.email}</h2>
      </>
    );
}
}
//base FUNZIONANTE per ottenere elementi. Ricorda che se vi è un solo risultato devi sostituire map con 
//this.state.persons.name(nel caso specifico)
