import React from 'react';
import axios from 'axios';
import './App.css';

import NamesContainer from './NamesContainer';
import { Input } from 'antd';


class SearchBar extends React.Component {

    
  state = {
    names: [
      
    ],
    searchTerm: ''
  }
componentDidMount(){
    let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("COToken"),
        },
      };
    axios.get('/api/studenti', config).
    then(res=>{console.log(res.data);
        this.setState({names: res.data});
    });
}
 
  editSearchTerm = (e) => {
    this.setState({searchTerm: e.target.value})
  }
  
  dynamicSearch = () => {
    return this.state.names.filter(item => item.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
  }


    render(){
        console.log(this.state.names);
      return (
        <div style = {{textAlign: 'center', paddingTop: '3vh'}}>
          <Input type= 'text' value = {this.state.searchTerm} onChange = {this.editSearchTerm} placeholder = 'Cerca utente'/>
          <br></br>
          <h3>Risultati</h3>
          <NamesContainer names = {this.dynamicSearch()}/>
        </div>
      );
    }
}

export default SearchBar;