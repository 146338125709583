import {Form, Input, Button, Checkbox} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import React, {useState, useEffect} from 'react';
import './App.css';
import {useHistory} from 'react-router-dom'
import axios from 'axios';
import {UserOutlined} from '@ant-design/icons';


function Registrati({match}) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const data = {'code': match.params.id, 'status': values.status, 'password': values.status};
    setLoading(true);
    axios.post('/api/register', data).then(response => {
      if (response.data.success === true) {
        history.push('/');
      } else {
        setErrorMessage('Verificare la password o il link ottenuto tramite email.');
      }
    }).catch(error => {
      setErrorMessage('Si è verificato un errore, riprovare più tardi');
      console.log(error);
    }).then(() => {
      setLoading(false);
    });
  }
  return (
    <div id="login-wrapper">
      <Form style={{'marginTop': '5%'}}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
      >

        <h1>Registrati su Cavour Online</h1>

        <Form.Item
          name="password"
          rules={[
            {
              min: 4,
              required: true,
              message: 'Per favore, inserisci una password',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item name="status">
          <Input
            prefix={<UserOutlined className="site-form-item-icon"/>}
            placeholder="Inserisci una bio"
          />
        </Form.Item>


        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Completa la registrazione
          </Button>
          {errorMessage ? <div className="error">{errorMessage}</div> : ""}
        </Form.Item>
      </Form>
    </div>
  );
}

export default Registrati